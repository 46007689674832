<template>
  <li class="checkbox">
    <div class="checkbox__item_wrapper">
      <input
        :id="inputId"
        v-model="checkbox"
        type="checkbox"
        :name="item.value.toString()"
        class="checkbox__item"
      />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <label class="checkbox__text" :for="inputId" v-html="item.name"></label>
    </div>
    <span v-if="typeof item.count === 'number'" class="checkbox__count">
      {{ item.count }}
    </span>
    <question-svg v-else-if="typeof item.count === 'string'"></question-svg>
  </li>
</template>

<script setup lang="ts">
import 'sass/base/checkbox.scss';

import {type PropType, computed} from '#imports';
import QuestionSvg from 'svg/header/question.vue';

const props = defineProps({
  modelValue: {type: Boolean, required: true},
  item: {
    type: Object as PropType<{name: string; value: string | number; count?: string | number}>,
    required: true,
  },
  id: {type: String, required: false},
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const checkbox = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const inputId = computed(() => (props.id || props.item.value).toString());
</script>
